import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import CallToActionDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/CallToActionDataTransformerBlock';
import { styled } from '@activebrands/core-web/libs/styletron';

const HeroWrapper = styled('section', ({ $isGrid }) => ({
    width: '100%',
    height: '100vh',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
}));

const ContentWrapper = styled('section', ({ $isGrid }) => ({
    aspectRatio: 'var(--ratio-vertical-primary)',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
    width: '100%',
    maxHeight: 'calc(100vh - var(--height-header))',
    height: '100%',

    [media.min['tablet.sm']]: {
        aspectRatio: 'var(--ratio-square)',
    },

    [media.min['desktop.sm']]: {
        aspectRatio: 'var(--ratio-horizontal-primary)',
    },
}));

const CallToActionModule = ({ isGrid = false, isHero = false, ...rest }) => {
    if (isHero) {
        return (
            <HeroWrapper $isGrid={isGrid}>
                <CallToActionDataTransformerBlock isHero {...rest} />
            </HeroWrapper>
        );
    }

    return (
        <ContentWrapper $isGrid={isGrid}>
            <CallToActionDataTransformerBlock {...rest} />
        </ContentWrapper>
    );
};

CallToActionModule.propTypes = {
    isGrid: PropTypes.bool,
    isHero: PropTypes.bool,
};

export default CallToActionModule;
